import React from 'react'

import wrapWithProvider from './src/redux/wrapper'

import "typeface-bitter"
import "typeface-raleway"
import "./src/css/style.css";

export const wrapRootElement = wrapWithProvider

export const onServiceWorkerUpdateReady = () => window.location.reload()