import { geoConstants } from '../constants'

const initialState = {
    data: null,
    loading: false,
    error: null
}

export function geo(state = initialState, action) {
    switch (action.type) {
        case geoConstants.GET_GEO_REQUEST: {
            return {
                data: null,
                loading: true,
                error: null
            }
        }

        case geoConstants.GET_GEO_SUCCESS: {
            const currentDate = new Date()

            const esArgentina = action.geo.country_code === 'AR'
            const expires = new Date(currentDate.setMonth(currentDate.getMonth() + 6)).toDateString()

            localStorage.setItem('geo', JSON.stringify({
                esArgentina: esArgentina,
                expires: expires
            }))

            return {
                data: {
                    esArgentina: esArgentina,
                    expires: expires
                },
                loading: false,
                error: null
            }
        }

        case geoConstants.GET_GEO_ERROR:
        {
            const currentDate = new Date()
            const expiresWithError = new Date(currentDate.setMonth(currentDate.getDay() + 1)).toDateString()

            localStorage.setItem('geo', JSON.stringify({
                esArgentina: false,
                expires: expiresWithError
            }))

            return {
                data: {
                    esArgentina: false,
                    expires: expiresWithError
                },
                loading: false,
                error: action.error
            }
        }

        case geoConstants.GET_GEO_SET: {
            return {
                data: action.geo,
                loading: false,
                error: null
            }
        }

        default:
            return state
    }
}