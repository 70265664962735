import PropTypes from 'prop-types'
import React from 'react'
import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { Provider } from 'react-redux'

import * as reducers from './reducers'

const initialState = {
    
}

const middleware = [thunk]

const combinedReducers = combineReducers({ ...reducers })

const store = createStore(
    combinedReducers, 
    initialState, 
    applyMiddleware(...middleware)
)

const ReduxWrapper = ({ element }) => <Provider store={store}>{element}</Provider>

ReduxWrapper.propTypes = {
    element: PropTypes.node
}

export default ReduxWrapper